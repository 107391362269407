import type { Reroute } from '@sveltejs/kit';

export const reroute: Reroute = ({ url }) => {

    if (url.hostname.startsWith('offer')) {


        return '/offer.fergmar.com' + url.pathname;
    }
 //   if (url.hostname.startsWith('app')) {
 //       return '/app.fergmar.com' + url.pathname;
 //   }
    return url.pathname;  
};

